<template>
  <div class="village-info-container">
    <div class="village-info-top">
      <div class="case-all-top-title">
        {{ t('HR001.queryConditionTitle') }}
      </div>
      <div class="case-all-top-group">
        <a-select
          ref="select"
          v-model:value="selectedVillage"
          style="width: 220px"
          :options="villageOptions"
          :placeholder="placeHolderChooseVillage"
        ></a-select>
      </div>
      <div class="case-all-top-date">
        <a-range-picker
          style="width: 220px"
          :ranges="ranges"
          show-time
          v-model:value="fromToDatelist"
          format="YYYY/MM/DD"
          @change="onChangeDate"
        />
      </div>
      <div class="case-all-top-confirm">
        <a-button
          class="confirm-button"
          @click="onClickQueryBtn"
          shape="round"
          :disabled="isQueryBtnDisable"
        >
          {{ t('HR001.query') }}
        </a-button>
      </div>
      <div class="case-all-top-export">
        <a-button
          class="export-button"
          @click="clickExport"
          :disabled="!isExportON"
          :loading="isExportLoading"
        >
          <template #icon><ExportOutlined /></template>
          {{ t('HR001.export') }}
        </a-button>
      </div>
    </div>
    <div class="village-info-detail">
      <a-row
        type="flex"
        justify="center"
        style="padding-top: 18px; padding-bottom: 40px"
      >
        <a-col flex="center">
          <a-radio-group
            v-model:value="villageDisplayMode"
            class="switch-mode-control"
            button-style="solid"
          >
            <a-radio-button class="mode-btn-shadow" value="villageListMode">
              清單
            </a-radio-button>
            <a-radio-button value="villageCardMode">看板</a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
      <template v-if="villageDisplayMode === 'villageListMode'">
        <VillageList
          :statistical-data="statisticalData"
          @clickItem="clickItem"
        ></VillageList>
      </template>
      <template v-if="villageDisplayMode === 'villageCardMode'">
        <VillageCardList
          :statistical-data="statisticalData"
          @clickItem="clickItem"
        ></VillageCardList>
      </template>
    </div>
  </div>
</template>
<script setup>
  import dayjs from 'dayjs'
  import { useRoute, useRouter } from 'vue-router'
  import { ExportOutlined } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { ref, computed, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import VillageCardList from './components/VillageCardList.vue'
  import VillageList from './components/VillageList.vue'
  import { getDealerDistrictExport } from '@/api/statistics'

  const { t } = useI18n()
  const route = useRoute()
  const router = useRouter()

  const ranges = {}
  ranges[t('DatePicker.today')] = [dayjs(), dayjs()]
  ranges[t('DatePicker.yesterday')] = [
    dayjs().add(-1, 'day'),
    dayjs().add(-1, 'day'),
  ]
  ranges[t('DatePicker.thisMonth')] = [
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ]
  ranges[t('DatePicker.latestMonth')] = [dayjs().add(-1, 'month'), dayjs()]
  ranges[t('DatePicker.latestThreeMonths')] = [
    dayjs().add(-3, 'month'),
    dayjs(),
  ]
  ranges[t('DatePicker.latestYear')] = [dayjs().add(-1, 'year'), dayjs()]

  const placeHolderChooseVillage = t('DealerDashboard.chooseVillage')
  const fromToDatelist = ref([])
  const fromDate = ref(0)
  const toDate = ref(0)

  const selectedVillage = ref('所有行政區')
  let selectedVillageConfirm = '所有行政區'

  const villageOptions = ref([
    { value: '所有行政區', label: '所有行政區' },
    // { value: '土城區', label: '土城區' },
    // { value: '土城區', label: '土城區' },
    // { value: '鶯歌區', label: '鶯歌區' },
    // { value: '萬里區', label: '萬里區' },
    // { value: '三重區', label: '三重區' },
    // { value: '中和區', label: '中和區' },
    // { value: '八里區', label: '八里區' },
    // { value: '蘆洲區', label: '蘆洲區' },
    // { value: '林口區', label: '林口區' },
    // { value: '汐止區', label: '汐止區' },
    // { value: '泰山區', label: '泰山區' },
    // { value: '淡水區', label: '淡水區' },
    // { value: '瑞芳區', label: '瑞芳區' },
    // { value: '石碇區', label: '石碇區' },
    // { value: '石門區', label: '石門區' },
    // { value: '五股區', label: '五股區' },
    // { value: '平溪區', label: '平溪區' },
    // { value: '貢寮區', label: '貢寮區' },
    // { value: '坪林區', label: '坪林區' },
    // { value: '金山區', label: '金山區' },
    // { value: '三芝區', label: '三芝區' },
    // { value: '永和區', label: '永和區' },
    // { value: '新店區', label: '新店區' },
    // { value: '板橋區', label: '板橋區' },
    // { value: '樹林區', label: '樹林區' },
    // { value: '深坑區', label: '深坑區' },
    // { value: '三峽區', label: '三峽區' },
    // { value: '烏來區', label: '烏來區' },
    // { value: '新莊區', label: '新莊區' },
  ])

  const isExportLoading = ref(false)

  const villageDisplayMode = ref('villageListMode')

  const store = useStore()

  // Vuex action mapping
  const getDealerDistrictAbnormal = (postBody) =>
    store.dispatch('statistics/getDealerDistrictAbnormal', postBody)
  const clearDealerDistrictAbnormalData = () =>
    store.dispatch('statistics/clearDealerDistrictAbnormalData')
  const getDealerClientAbnormal = (postBody) =>
    store.dispatch('statistics/getDealerClientAbnormal', postBody)
  const clearDealerClientAbnormalData = () =>
    store.dispatch('statistics/clearDealerClientAbnormalData')
  const getDealerClient = () => store.dispatch('statistics/getDealerClient')

  const dealerDistrictAbnormal = computed(
    () => store.state.statistics.dealerDistrictAbnormal
  )
  const dealerClientAbnormal = computed(
    () => store.state.statistics.dealerClientAbnormal
  )
  const dealerClient = computed(() => store.state.statistics.dealerClient)
  const dealerQueryTimeCondition = computed(
    () => store.state.statistics.dealerQueryTimeCondition
  )

  const statisticalData = ref([])

  const isQueryBtnDisable = computed(() => {
    let isQueryBtnDisable = true
    if (fromDate.value > 0 && toDate.value > 0) {
      isQueryBtnDisable = false
    }

    return isQueryBtnDisable
  })

  const isExportON = computed(() => {
    if (
      fromDate.value > 0 &&
      toDate.value > 0 &&
      selectedVillage.value &&
      selectedVillage.value != '所有行政區'
    ) {
      return true
    }
    return false
  })

  onMounted(async () => {
    await getDealerClient()
    clearDealerDistrictAbnormalData()
    clearDealerClientAbnormalData()
    if (
      dealerQueryTimeCondition.value &&
      dealerQueryTimeCondition.value.startTs > 0 &&
      dealerQueryTimeCondition.value.endTs > 0
    ) {
      fromToDatelist.value.push(dayjs(dealerQueryTimeCondition.value.startTs))
      fromToDatelist.value.push(dayjs(dealerQueryTimeCondition.value.endTs))
      fromDate.value = dealerQueryTimeCondition.value.startTs
      toDate.value = dealerQueryTimeCondition.value.endTs
    } else {
      const today = dayjs()
      const threeMonthAgo = dayjs().add(-3, 'month')
      fromDate.value = threeMonthAgo.unix() * 1000
      toDate.value = today.unix() * 1000
      fromToDatelist.value = []
      fromToDatelist.value.push(threeMonthAgo)
      fromToDatelist.value.push(today)
    }

    selectedVillage.value = route.query.district
      ? route.query.district
      : '所有行政區'
    selectedVillageConfirm = selectedVillage.value
    onClickQueryBtn()
  })

  const onChangeDate = (date, dateString) => {
    if (dateString && dateString.length == 2) {
      const newFromDate = dayjs(dateString[0]).startOf('day').unix()
      const newToDate = dayjs(dateString[1]).endOf('day').unix()
      fromDate.value = newFromDate * 1000
      toDate.value = newToDate * 1000
    }
  }

  const onClickQueryBtn = () => {
    if (fromDate.value <= 0 || toDate.value <= 0) {
      return
    }

    selectedVillageConfirm = selectedVillage.value
    if (selectedVillage.value === '所有行政區') {
      getDealerDistrictAbnormal({
        startTs: fromDate.value,
        endTs: toDate.value,
      })
    } else {
      getDealerClientAbnormal({
        city: '新北市',
        district: selectedVillage.value,
        startTs: fromDate.value,
        endTs: toDate.value,
      })
    }
  }

  const clickItem = (village) => {
    if (selectedVillageConfirm === '所有行政區') {
      return
    }
    router.push({
      name: 'MeasurementsAll',
      query: {
        village: village,
      },
    })
  }

  const clickExport = async () => {
    isExportLoading.value = true
    try {
      if (fromDate.value == 0 && toDate.value == 0) {
        return
      }

      const params = {
        city: '新北市',
        district: selectedVillage.value,
        lang: 'tw',
        startTs: fromDate.value,
        endTs: toDate.value,
      }
      const respData = await getDealerDistrictExport(params)
      let fileName = 'Report.xlsx'
      let contentDisposition = respData.headers['content-disposition']
      if (!contentDisposition) {
        contentDisposition = respData.headers['Content-Disposition']
      }
      if (contentDisposition) {
        fileName = contentDisposition.split(' ')[1].replace('filename=', '')
      }
      const url = window.URL.createObjectURL(new Blob([respData.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      isExportLoading.value = false
    } catch (e) {
      isExportLoading.value = false
      console.log(e)
    }
  }

  watch(dealerDistrictAbnormal, (current, prev) => {
    if (selectedVillageConfirm === '所有行政區') {
      statisticalData.value = []
      current.forEach((element) => {
        const newData = { ...element }
        newData.title = newData.district
        statisticalData.value.push(newData)
      })
    }
  })

  watch(dealerClientAbnormal, (current, prev) => {
    if (selectedVillageConfirm !== '所有行政區') {
      statisticalData.value = []
      current.forEach((element) => {
        statisticalData.value.push(element)
      })
    }
  })

  watch(dealerClient, (current, prev) => {
    villageOptions.value = [{ value: '所有行政區', label: '所有行政區' }]
    const districts = [...new Set(current.map((item) => item.district))]
    districts.forEach((name) => {
      villageOptions.value.push({
        value: name,
        label: name,
      })
    })
  })
</script>
<style lang="less" scoped>
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color: #4ebaa8;
    border-color: #4ebaa8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #4dbaa7;
    // background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }

  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .village-info-container {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    background-color: #e5e5e5;

    .village-info-top {
      .clear-fix-float();
      //margin-bottom: 20px;
      //height: 100px;
      min-height: 100px;
      height: auto;
      background-color: #ffffff;
      border-radius: 10px;

      .case-all-top-title {
        width: 72;
        height: 32;
        float: left;
        margin-top: 40px;
        margin-left: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 89% */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.5);
      }
      .case-all-top-date {
        width: 226;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-group {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-item {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-confirm {
        width: 85;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .confirm-button {
          color: white;
          background: #4dbaa7;
          border-radius: 6px;
          &:focus {
            -webkit-appearance: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .case-all-top-export {
        width: 110px;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .export-button {
          &:focus {
            -webkit-appearance: none;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.85);
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .village-info-detail {
      .clear-fix-float();
      margin-top: 20px;
      //border-radius: 25px;
      //background-color: cornflowerblue;
      width: 100%;
      //height: 200px;
      background: #ffffff;

      .switch-mode-control {
        margin-top: 18px;
        .mode-btn-shadow {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 2px;
        }
      }
    }
  }
</style>
